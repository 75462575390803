/* --------------------------------

Taboola-Icons Web Font - nucleoapp.com/
License - nucleoapp.com/license/

-------------------------------- */
@import "variables";
@import "mixins";

@font-face {
  font-family: $tbl-icons-font-stack;
  src: url('#{$tbl-font-path}/taboola-icons.eot');
  src: url('#{$tbl-font-path}/taboola-icons.eot') format('embedded-opentype'),
    url('#{$tbl-font-path}/taboola-icons.woff2') format('woff2'),
    url('#{$tbl-font-path}/taboola-icons.woff') format('woff'),
    url('#{$tbl-font-path}/taboola-icons.ttf') format('truetype'),
    url('#{$tbl-font-path}/taboola-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.tbl-icon {
  display: inline-block;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: inherit;
  line-height: inherit;
  font-family: $tbl-icons-font-stack;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    vertical-align: middle;
  }
}


/*------------------------
  change icon size
-------------------------*/

.tbl-icon.lg {
	font-size: 1.33333333em;
  	vertical-align: -16%;
}

.tbl-icon.x2 {
	font-size: 2em;
}

.tbl-icon.x3 {
	font-size: 3em;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.tbl-icon.square,
.tbl-icon.circle {
  padding: $tbl-padding-width;
  vertical-align: -16%;
  background-color: $tbl-background-color;
}

.tbl-icon.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

.#{$tbl-css-prefix}-ul {
  padding-left: 0;
  margin-left: $tbl-li-width;
  list-style-type: none;

  > li {
    position: relative;
  }

  > li > .tbl-icon {
    position: absolute;
    left: -$tbl-li-width/2 - 0.5em;
    top: (2em / 14);
    text-align: center;

    &.lg {
      top: 0;
      left: -$tbl-li-width/2 - (4em / 14);
    }

    &.circle,
    &.square {
      top: (2em / 14) - $tbl-padding-width;
      left: -$tbl-li-width/2 - 0.5em - $tbl-padding-width;
    }
  }
}

/*------------------------
  spinning icons
-------------------------*/

.tbl-icon.spin {
  -webkit-animation: tbl-icon-spin 2s infinite linear;
  -moz-animation: tbl-icon-spin 2s infinite linear;
  animation: tbl-icon-spin 2s infinite linear;
}

@-webkit-keyframes tbl-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes tbl-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes tbl-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.tbl-icon.rotate-90  {
	@include tbl-rotate(90deg, 1);
}

.tbl-icon.rotate-180 {
	@include tbl-rotate(180deg, 2);
}

.tbl-icon.rotate-270 {
	@include tbl-rotate(270deg, 3);
}

.tbl-icon.flip-y {
	@include tbl-flip(-1, 1, 0);
}
.tbl-icon.flip-x {
	@include tbl-flip(1, -1, 2);
}

@import "icons";
