@import '../../styles/main';

// Override Semantic-UI defaults
.ui.modal>.header:not(.ui) {
  font-family: 'ArialMT', 'Arial';
  font-weight: bold;
  font-style: normal;
  font-size: 2rem;
}

.ui.modal>.content {
  font-family: 'Helvetica';
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
}

.ui.modal .tbl-styled-btn {
  margin-left: 0.5rem;
}
