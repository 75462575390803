@import 'main';
//
//:global {
//  #root {
//    height: 100vh;
//    overflow-y: hidden;
//  }
//}
//
html {
  font-size: $root-font-size;
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: $regular-font-stack;
  font-size: $font-size-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  color: $text
}

html[lang=ja], html[lang=ko], html[lang=zh] {
  svg ~ * {
    word-break: keep-all;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a:link,
a:visited {
  outline: none;
}
a:hover,
a:active {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
ul{
  margin: 0;
}

img {
  max-width: 100%;
}
