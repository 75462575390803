@import "src/styles/main";

.tbl-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: 0;

    &:disabled {
        cursor: default;
    }
}

.export-button {
    display: flex;
    margin-bottom: 1em;
}

.export-button .button {
    text-transform: uppercase;
    border-radius: 6px;
    font-style: normal;
    font-stretch: normal;
    font-size: $font-size-small;
    font-family: $regular-font-stack;

    &:hover {
        background-color: $green-4;
    }

    &:active {
        background-color: $green-6;
    }

    &:disabled {
        background-color: $gray-3;
        color: $gray-5;
    }
}

.tbl-styled-btn {
    border-radius: 6px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: rem(0.5px);
    text-align: left;
    text-transform: uppercase;
    font-size: $font-size-small;
    font-family: $regular-font-stack;
    line-height: normal;
    border: transparent;
    color: $white;
    background-color: $green-5;

    .tbl-styled-icon-before {
        font-size: $font-size-medium;
        padding-right: rem(3px);
    }

    .tbl-styled-icon-after {
        font-size: $font-size-medium;
        padding-left: rem(3px);
    }

    //STRONG BUTTON
    &.strong {
        line-height: normal;
        border: transparent;
        color: $white;
        background-color: $green-5;
        font-family: $regular-font-stack;

        &:hover {
            background-color: $green-4;
        }

        &:active {
            background-color: $green-6;
        }

        &:disabled {
            background-color: $gray-3;
            color: $gray-5;
        }
    }

    //GHOST BUTTON
    &.ghost {
        line-height: normal;
        border: border($green-5);
        color: $green-5;
        background-color: $white;

        &.primary {
            padding: rem(0) rem(12px);

            &:hover {
                border: border($green-6);
                color: $green-6;
            }

            &:active {
                border: border($green-4);
                color: $green-6;
            }

            &:disabled {
                border: border($gray-4);
                color: $gray-5;
            }
        }

        &.large {
            padding: rem(0) rem(18px);
            color: $gray-7;
            border: border($gray-6);
        }

        &.medium {
            padding: rem(0) rem(13px);
            color: $gray-7;
            border: border($gray-6);
        }

        &.small {
            text-transform: none;
            padding: rem(0) rem(8px);
            color: $gray-7;
            border: border($gray-6);
        }

        &:hover {
            border: border($gray-7);
            color: $gray-8;
        }

        &:active {
            border: border($gray-7);
            color: $gray-6;
        }

        &:disabled {
            border: border($gray-4);
            color: $gray-5;
        }
    }
}

//BORDERLESS ICON
.borderless-icon {
    color: $gray-7;
    letter-spacing: 0;
    border: none;
    background-color: transparent;

    .tbl-styled-icon-before,
    .tbl-styled-icon-after {
        padding: 0;
    }

    &:hover {
        background-color: $gray-2;
    }

    &:active {
        background-color: $gray-4;
    }

    &:disabled {
        background-color: transparent;
        color: $gray-5;
    }

    &.primary,
    &.large,
    &.medium,
    &.small {
        padding: rem(1px);
    }

    &.primary .tbl-styled-icon-before,
    &.primary .tbl-styled-icon-after {
        font-size: $font-size-xx-large;
    }

    &.large .tbl-styled-icon-before,
    &.large .tbl-styled-icon-after {
        font-size: $font-size-larger;
    }

    &.medium .tbl-styled-icon-before,
    &.medium .tbl-styled-icon-after {
        font-size: $font-size-medium;
    }

    &.small .tbl-styled-icon-before,
    &.small .tbl-styled-icon-after {
        font-size: $font-size-small;
    }
}

.link {
    @extend .borderless-icon;
    color: $blue;

    &:hover,
    &:active {
        background: none;
    }

    &:hover {
        color: $blue-5;
        text-decoration: underline;
    }

    &:disabled {
        color: $gray-7;
        pointer-events: none;
    }
}

.primary {
    padding: rem(0) rem(18px);
    height: rem(43px);
}

.large {
    padding: rem(0) rem(16px);
    height: rem(43px);
}

.medium {
    padding: rem(0) rem(13px);
    height: rem(34px);
}

.small {
    text-transform: none;
    padding: rem(0) rem(9px);
    height: rem(26px);
}
