.body {
    background-image: none;
    position: static;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    text-align: left;

    font-family: 'Helvetica';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-align: left;
    text-align: left;
    line-height: normal;
}

.main-container {
    color: #555;
    font-size: 17px;
    line-height: 160%;
    font-family: HelveticaNeueLTStd-Lt,sans-serif;
    font-weight: 200;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    padding-bottom: 1em;
}

#user-data {
    display: none;
}

article {
    box-sizing: border-box;
}

.label {
    font-family: 'Lato-Regular', 'Lato';
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #9CA8B5;
    text-align: left;
    padding-right: 5px;
}

.image {
}

.text_input {
    font-family: 'ArialMT', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #666666;
}

.search-input {

}

.button_main_action-lg {
    font-family: 'Lato-Regular', 'Lato';
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 14px;
}

.custom_input_background {
}

.custom_input_background__selected_ {
}

.custom_input_tick_mark {
}

.checkbox_label {
    font-family: 'Lato-Regular', 'Lato';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #3C3C3C;
    text-align: left;
}

.heading_1 {
    font-family: 'ArialMT', 'Arial';
    font-weight: bold;
    font-style: normal;
    font-size: 32px;
    text-align: left;
}

.heading_segments {
    font-family: 'ArialMT', 'Arial';
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    text-align: left;
}

.user_info_container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#status{
    right: 0;
    border-width: 0px;
    position: absolute;
    top: 10px;
    width: 213px;
    height: 52px;
    overflow: hidden;
}

p {

    box-sizing: border-box;
    color: rgb(85, 85, 85);
    text-align: left;
    text-decoration: none solid rgb(85, 85, 85);
    text-size-adjust: 100%;
    column-rule-color: rgb(85, 85, 85);
    perspective-origin: 615px 54px;
    transform-origin: 615px 54px;
    caret-color: rgb(85, 85, 85);
    border: 0px none rgb(85, 85, 85);
    font: normal normal 200 normal 17px / 27.2px HelveticaNeueLTStd-Lt, sans-serif;
    margin: 0px 0px 10px;
    outline: rgb(85, 85, 85) none 0px;
}

.h3 {
    font-weight: bold;
}

.button_text_dark {
    font-family: 'Lato-Regular', 'Lato';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #222C38;
    text-align: left;
}

.label_lg_ad_console_form {
    font-family: 'Lato-Regular', 'Lato';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #212832;
    text-align: left;
}

table, th, td {
    border: 1px solid black;
}

table {
    width: 100%;
}

.btn {
    border-width: 0px;
    min-width: 285px;
    height: 50px;
    background: inherit;
    background-color: rgba(68, 114, 196, 1);
    border: none;
    border-radius: 30px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Helvetica';
    font-weight: 400;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 14px;
}

td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
}


.alert-success h4 {
    color: #468847;
}

.alert-danger,
.alert-error {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

.alert-danger h4,
.alert-error h4 {
    color: #b94a48;
}

#access-data-title {
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    height: 220px;
    text-decoration: none solid rgb(51, 51, 51);
    text-size-adjust: 100%;
    column-rule-color: rgb(51, 51, 51);
    perspective-origin: 720px 130px;
    transform-origin: 720px 130px;
    caret-color: rgb(51, 51, 51);
    border: 0px none rgb(51, 51, 51);
    font: normal normal 400 normal 14px / 20px HelveticaNeueLTStd-Lt, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}

#access-data-section {
    background-position: 50% 0%;
    bottom: 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    height: 220px;
    left: 0px;
    position: relative;
    right: 0px;
    text-decoration: none solid rgb(255, 255, 255);
    text-size-adjust: 100%;
    top: 0px;
    column-rule-color: rgb(255, 255, 255);
    perspective-origin: 720px 130px;
    transform-origin: 720px 130px;
    caret-color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0) url(./../images/sub-page-banner.png) no-repeat scroll 50% 0% / cover padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    font: normal normal 400 normal 14px / 20px HelveticaNeueLTStd-Lt, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
    padding: 0px 0px 0px;
}

#h1 {
    font-size: 50px;
    line-height: 1;
    margin: 0;
    font-weight: 300;
    text-align: center;
    padding-top: 90px;
}

#removeAudience {
    color: #337AB7;
    border: none;
    font-size: 14px;

}

.logo {

    float: left;
    margin-left: 10px;
    margin-top: 10px;
}

.paginate_button {
    padding-right: 3px;
    color: rgba(68, 114, 196, 1);
}

#table-filter {
    padding-top: 5px;
}

.dataTables_filter {
    padding-bottom: 10px;
}

.dataTables_info {
    padding-top: 10px;
}

.btn-comment, .btn-comment > *{
    font-size: 10px;
}

.btn-table, .btn-table tbody th,.btn-table tbody td{
    border:0;
}

#opt-in {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.form {
    display: block;
    margin-bottom: 1em;
}

.formGroup {
    margin-bottom: 1em;
}

.tbl-checkbox label{
    padding-left: 0.25em;
}