@import '../../styles/main';
@import './DataGridVariables.scss';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat.scss';

:export {
  rowHeight: $row-height;
  headerHeight: $header-height;
  footerHeight: $footer-height;
}

.taboola-theme {

  // Reset unconfigurable styles of ag-grid
  background-color: $internal-background-color !important;
  border-radius: 6px 6px 0 0;
  margin-bottom: 4rem;
  width: 800px;

  .ag-header {
    color: $gray-9;
    font: $regular-bolder $font-size-medium-small $regular-font-stack;

    .ag-cell-label-container {
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      max-height: $header-height;
    }

    .ag-header-cell .ag-react-container {
      overflow: hidden;
      width: 100%;
    }
  }

  .ag-body {
    background-color: $internal-background-color !important;
  }

  .ag-row {
    border: 0;
  }

  .ag-row.disabled {
    background-color: $gray-3;
    color: $gray-5;
  }

  .ag-row-odd {
    &.ag-row-selected {
      background-color: $selected-color;
    }
  }
  .ag-row-even {
    background-color: $gray-1;
    &.ag-row-selected {
      background-color: $secondary-selected-color;
    }
  }

  .ag-pinned-left-header,
  .ag-pinned-right-header,
  .ag-header-cell,
  .ag-status-bar,
  .ag-side-bar {
    border: 0;
  }

  .ag-header-cell-resize {
    z-index: auto !important;
    width: rem(4px);
    cursor: ew-resize;
    right: 0;

    &:hover {
      background-color: $blue;
    }

    &:active {
      background-color: $blue-5;
    }

    &::after {
      display: none !important;
    }
  }


  .ag-header-cell {
    .ag-header-icon {
      display: none;
    }

    .drag-and-drop-icon {
      display: none;
    }

    &:hover {
      box-shadow:inset -4px 0 0px 0px $gray-4, -1px 0 0px 0px $gray-4;

      .drag-and-drop-icon {
        color: $gray-5;
        cursor: move;
        vertical-align: middle;
        margin-left: rem(-6px);
        display: inline-block;
      }
    }

    .drag-and-drop-icon {
      &:hover {
        color: $blue;
      }

      &:active {
        color: $blue-5;
      }
    }
  }

  .ag-row-focus {
    z-index: auto !important;
  }

  .ag-cell-focus {
    border-right: 1px solid $border-color !important;
    z-index: auto !important;
  }

  .ag-tool-panel {
    border-right: none !important;
  }

  // **************************************

  // hover color settings
  .ag-row-hover {
    color: $black;
    background-color: rgba($blue-2, 0.2);

    .ag-cell {
      border-right: 1px solid $gray-1;
    }
  }

  // **************************************


  .ag-root {
    border-radius: 6px 6px 0 0;
  }

  .ag-react-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ag-overlay {
    height: auto;
    width: calc(100% + #{2 * $border-width});
    left: -1 * $border-width;
  }

  .ag-layout-normal .ag-overlay {
    height: 100%;
  }

  .ag-layout-auto-height, .ag-layout-normal {
    &.ag-overlay-no-rows-wrapper {
      padding-top: $header-height;

      .ag-react-container {
        width: 100%;
        border: border($gray-4, $border-width);
        border-top-width: 0;
      }
    }

    &.ag-body-viewport {
      min-height: $no-rows-overlay-height;
    }
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-right-cols-container {
    position: relative;

    &:after {
      content: "";
      width: rem(1px);
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: border($border-color);
      box-sizing: border-box;
    }
  }

  .ag-pinned-left-cols-container:after {
    right: rem(3px);
    box-shadow: 1px 0 1px 0 rgba($black, .1);
  }

  .ag-pinned-right-cols-container:after {
    left: rem(3px);
    box-shadow: -1px 0 1px 0 rgba($black, .1);
  }
}

.grid-container.with-footer .taboola-grid {
  :global {
    .ag-center-cols-viewport {
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .ag-body-horizontal-scroll {
      display: none;
    }

    .ag-root {
      border-bottom: 0;
    }
  }
}

.taboola-grid {
  &.sticky {
    :global {
      .ag-root-wrapper {
        overflow: visible;

        .ag-root {
          z-index: 0;
          overflow: visible;

          .ag-header {
            position: sticky;
            z-index: 1;
            top: -20px;
            border-radius: 6px 6px 0px 0px;
          }
        }
      }
    }
  }

  &.disable-selected-rows {
    :global {
      .ag-row-selected {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  @keyframes :global(loading-animation) {
    0% { opacity: 1;}
    50% { opacity: .5;}
    100% { opacity: 1;}
  }

  %basic-cell-placeholder {
    color: transparent;
    height: $row-height;
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block !important;
    text-align: left;
    margin-right: 0;

    &::before {
      content: '';
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background-color: $gray-4;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  %empty-cell-placeholder {
    @extend %basic-cell-placeholder;

    &::before {
      opacity: .4;
    }
  }

  %loading-placeholder {
    @extend %basic-cell-placeholder;

    &::before {
      animation-name: loading-animation;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &.header-loading {
    :global {
      .ag-header-cell {
        >div:last-child:not(.ag-react-container),
        .ag-react-container,
        .ag-header-cell-text {
          @extend %loading-placeholder;

          * {
            display: none
          }
        }
      }
    }
  }

  &:not(.header-loading) {
    :global {
      .ag-header-cell {
        > div:last-child:not(.ag-react-container) {
          @extend %empty-cell-placeholder;

          * {
            display: none;
          }
        }

        .ag-react-container:empty {
          @extend %empty-cell-placeholder;
        }
      }

      .ag-header-cell:not(.no-label-header) {
        .ag-header-cell-text:empty {
          @extend %empty-cell-placeholder;
        }
      }
    }
  }

  &.rows-loading {
    :global {
      .ag-cell-value {
        @extend %loading-placeholder;

        > * {
          display: none;
        }
      }
    }
  }

  &:not(.rows-loading) {
    :global {
      .ag-cell-value:empty, .ag-cell-value .ag-react-container:empty {
        @extend %empty-cell-placeholder;
      }
    }
  }

}

.footer {

  border-radius: 0 0 6px 6px !important;

  &.sticky {
    position: sticky;
    bottom: 0;
  }

  :global {
    color: $secondary-foreground-color !important;
    font-weight: $secondary-font-weight !important;
    background-color: $internal-background-color !important;

    .ag-body-viewport {
      overflow-y: hidden;
    }

    .ag-root {
      border-radius: 0 0 6px 6px !important;
      border-top-width: 2px;
    }

    .ag-row-even {
      background-color: $odd-row-background-color !important;
    }

    .ag-row-odd {
      background-color: $even-row-background-color !important;
    }

    .ag-header,
    .ag-cell,
    .ag-cell-focus,
    .ag-cell-no-focus {
      border: 0 !important;
    }

    .ag-body-horizontal-scroll {
      display: flex;

      .ag-horizontal-left-spacer,
      .ag-horizontal-right-spacer {
        border: none;
        overflow: hidden;
      }
    }

    .ag-pinned-left-cols-viewport,
    .ag-pinned-right-cols-viewport {
      overflow: hidden;
    }

    .ag-pinned-left-cols-container:after,
    .ag-pinned-right-cols-container:after {
      display: none;
    }
  }
}

.grid-container {
  height: auto;
  width: 100%;
  box-sizing: border-box;

  background-color: transparent !important;

  :global {

    .taboola-theme {

      // Reset unconfigurable styles of ag-grid
      background-color: $internal-background-color !important;
      border-radius: 6px 6px 0 0;

      .ag-header {
        color: $gray-9;
        font: $regular-bolder $font-size-medium-small $regular-font-stack;

        .ag-cell-label-container {
          flex-direction: row;
          justify-content: space-between;
          height: 100%;
          max-height: $header-height;
        }

        .ag-header-cell .ag-react-container {
          overflow: hidden;
          width: 100%;
        }
      }

      .ag-body {
        background-color: $internal-background-color !important;
      }

      .ag-row {
        border: 0;
      }

      .ag-row-odd {
        &.ag-row-selected {
          background-color: $selected-color;
        }
      }
      .ag-row-even {
        background-color: $gray-1;
        &.ag-row-selected {
          background-color: $secondary-selected-color;
        }
      }

      .ag-pinned-left-header,
      .ag-pinned-right-header,
      .ag-header-cell,
      .ag-status-bar,
      .ag-side-bar {
        border: 0;
      }

      .ag-header-cell-resize {
        z-index: auto !important;
        width: rem(4px);
        cursor: ew-resize;
        right: 0;

        &:hover {
          background-color: $blue;
        }

        &:active {
          background-color: $blue-5;
        }

        &::after {
          display: none !important;
        }
      }


      .ag-header-cell {
        .ag-header-icon {
          display: none;
        }

        .drag-and-drop-icon {
          display: none;
        }

        &:hover {
          box-shadow:inset -4px 0 0px 0px $gray-4, -1px 0 0px 0px $gray-4;

          .drag-and-drop-icon {
            color: $gray-5;
            cursor: move;
            vertical-align: middle;
            margin-left: rem(-6px);
            display: inline-block;
          }
        }

        .drag-and-drop-icon {
          &:hover {
            color: $blue;
          }

          &:active {
            color: $blue-5;
          }
        }
      }

      .ag-row-focus {
        z-index: auto !important;
      }

      .ag-cell-focus {
        border-right: 1px solid $border-color !important;
        z-index: auto !important;
      }

      .ag-tool-panel {
        border-right: none !important;
      }

      // **************************************

      // hover color settings
      .ag-row-hover {
        color: $black;
        background-color: rgba($blue-2, 0.2);

        .ag-cell {
          border-right: 1px solid $gray-1;
        }
      }

      // **************************************


      .ag-root {
        border-radius: 6px 6px 0 0;
      }

      .ag-react-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .ag-overlay {
        height: auto;
        width: calc(100% + #{2 * $border-width});
        left: -1 * $border-width;
      }

      .ag-layout-normal .ag-overlay {
        height: 100%;
      }
      
      .ag-layout-auto-height, .ag-layout-normal {
        &.ag-overlay-no-rows-wrapper {
          padding-top: $header-height;

          .ag-react-container {
            width: 100%;
            border: border($gray-4, $border-width);
            border-top-width: 0;
          }
        }

        &.ag-body-viewport {
          min-height: $no-rows-overlay-height;
        }
      }

      .ag-pinned-left-cols-container,
      .ag-pinned-right-cols-container {
        position: relative;

        &:after {
          content: "";
          width: rem(1px);
          position: absolute;
          top: 0;
          bottom: 0;
          border-left: border($border-color);
          box-sizing: border-box;
        }
      }

      .ag-pinned-left-cols-container:after {
        right: rem(3px);
        box-shadow: 1px 0 1px 0 rgba($black, .1);
      }

      .ag-pinned-right-cols-container:after {
        left: rem(3px);
        box-shadow: -1px 0 1px 0 rgba($black, .1);
      }
    }

    .ag-body-viewport {
      &.ag-layout-auto-height, &.ag-layout-auto-normal {
        & .ag-center-cols-clipper, & .ag-center-cols-container {
          min-height: $row-height;
        }
      }
    }

    .ag-theme-material {
      .ag-root {
        border: border($gray-4);
      }

      &.ag-popup {
        > div.ag-popup-editor {
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .ag-cell {
        padding: 0 rem(12px);
        font-size: $font-size-small;

        .ag-cell-wrapper {
          .ag-cell-value {
            display: inline-block;
            width: 100%;
          }
        }
      }

      .ag-ltr {
        .ag-cell-inline-editing {
          height: inherit;
          &.ag-cell-first-right-pinned {
            padding-left: rem(6px);
          }
          &.ag-cell-first-left-pinned {
            padding-right: rem(6px);
          }
        }
        .ag-cell-first-right-pinned {
          border-left: none !important;
        }
        .ag-cell-last-left-pinned {
          border-right: none !important;
        }
      }

      .ag-header {
        border-bottom-width: 2px;

        .ag-numeric-header {
          .ag-header-cell-label {
            float: left;
          }
        }
      }

      .ag-header-cell-label {
        align-items: center;

        .asc,.desc {
          width: rem(14px);
        }

        .ag-icon {
          height: rem(12px);
        }

        .ag-header-cell-text {
          line-height: 1.25;
          white-space: normal;
          height: auto;
          padding-right: rem(2px);
          padding-left: rem(2px);
          max-height: rem(50px);
        }
      }

      .ag-header-cell-menu-button .ag-icon {
        display: inline-block;
        height: rem(12px);
      }

      .ag-header-cell {
        padding-left: rem(12px);
        padding-right: rem(12px);
      }

      .ag-icon-container {
        display: flex;
      }
    }
  }

  &.with-footer .taboola-grid {
    :global {
      .ag-center-cols-viewport {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .ag-body-horizontal-scroll {
        display: none;
      }

      .ag-root {
        border-bottom: 0;
      }
    }
  }

  .taboola-grid {
    &.sticky {
      :global {
        .ag-root-wrapper {
          overflow: visible;

          .ag-root {
            z-index: 0;
            overflow: visible;
  
            .ag-header {
              position: sticky;
              z-index: 1;
              top: -20px;
              border-radius: 6px 6px 0px 0px;
            }
          }
        }
      }
    }

    &.disable-selected-rows {
        :global {
            .ag-row-selected {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    @keyframes :global(loading-animation) {
      0% { opacity: 1;}
      50% { opacity: .5;}
      100% { opacity: 1;}
    }

    %basic-cell-placeholder {
      color: transparent;
      height: $row-height;
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-block !important;
      text-align: left;
      margin-right: 0;

      &::before {
        content: '';
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background-color: $gray-4;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    %empty-cell-placeholder {
      @extend %basic-cell-placeholder;

      &::before {
        opacity: .4;
      }
    }

    %loading-placeholder {
      @extend %basic-cell-placeholder;

      &::before {
        animation-name: loading-animation;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    &.header-loading {
      :global {
        .ag-header-cell {
          >div:last-child:not(.ag-react-container),
          .ag-react-container,
          .ag-header-cell-text {
            @extend %loading-placeholder;

            * {
              display: none
            }
          }
        }
      }
    }

    &:not(.header-loading) {
      :global {
        .ag-header-cell {
          > div:last-child:not(.ag-react-container) {
            @extend %empty-cell-placeholder;

            * {
              display: none;
            }
          }

          .ag-react-container:empty {
            @extend %empty-cell-placeholder;
          }
        }

        .ag-header-cell:not(.no-label-header) {
          .ag-header-cell-text:empty {
            @extend %empty-cell-placeholder;
          }
        }
      }
    }

    &.rows-loading {
      :global {
        .ag-cell-value {
          @extend %loading-placeholder;

          > * {
            display: none;
          }
        }
      }
    }

    &:not(.rows-loading) {
      :global {
        .ag-cell-value:empty, .ag-cell-value .ag-react-container:empty {
          @extend %empty-cell-placeholder;
        }
      }
    }

  }

  .footer {

    border-radius: 0 0 6px 6px !important;

    &.sticky {
      position: sticky;
      bottom: 0;
    }

    :global {
      color: $secondary-foreground-color !important;
      font-weight: $secondary-font-weight !important;
      background-color: $internal-background-color !important;

      .ag-body-viewport {
        overflow-y: hidden;
      }

      .ag-root {
        border-radius: 0 0 6px 6px !important;
        border-top-width: 2px;
      }

      .ag-row-even {
        background-color: $odd-row-background-color !important;
      }

      .ag-row-odd {
        background-color: $even-row-background-color !important;
      }

      .ag-header,
      .ag-cell,
      .ag-cell-focus,
      .ag-cell-no-focus {
        border: 0 !important;
      }

      .ag-body-horizontal-scroll {
        display: flex;

        .ag-horizontal-left-spacer,
        .ag-horizontal-right-spacer {
          border: none;
          overflow: hidden;
        }
      }

      .ag-pinned-left-cols-viewport,
      .ag-pinned-right-cols-viewport {
        overflow: hidden;
      }

      .ag-pinned-left-cols-container:after,
      .ag-pinned-right-cols-container:after {
        display: none;
      }
    }
  }
}

.pagination {

  &.hidden {
    display: none;
  }
}
