@import '../../styles/main';

// internal variables
$internal-background-color: $white;
$even-row-background-color: $gray-1;
$odd-row-background-color: $internal-background-color;
$footer-height: 45px;
$border-width: rem(1px);

/***** ag-grid scss variables ******/
$icons-path: "/resources/assets/ag-grid/icons/";
$icon-color: $gray-6;
$font-family: $number-font-stack;
$primary-color: transparent;
// this is actually "even-row-background-color"
$background-color: $even-row-background-color;
// text color
$foreground-color: $gray-9;
$secondary-font-family: $font-family;
$secondary-font-weight: $regular-bolder;
// header text color
$secondary-foreground-color: $gray-9;
$border-color: $gray-2;
$header-background-color: $internal-background-color;
$header-height: 54px;
$row-height: 38px;
$selected-color: $yellow-2;
$secondary-selected-color: $yellow-1;
$focused-cell-border-color: transparent;
$cell-horizontal-border: border($gray-2);
$header-cell-hover-background-color: transparent;
/*****************************/

// overlay variables
$min-rows-in-overlay: 0;
$min-rows-in-empty-state-overlay: 8;
$no-rows-overlay-height: $row-height * $min-rows-in-overlay;