.tbl-language-container {
  position: absolute;
  right: 0.1rem;
  padding-top: 0.1rem;
  width: 200px;
}

.tbl-language-selector {
  font-size: 12px;
  line-height: 120%;
  font-family: HelveticaNeueLTStd-Lt,sans-serif;
  font-weight: 200;
  text-align: left;
}