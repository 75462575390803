@import '../../styles/main';
@import '../../styles/indication-colors';

$default-border: border($gray-5);
$error-border: border($errorColor);
$warning-border: border($warningColor);

$focused-border-color: $blue-5;
$focused-border: border($focused-border-color);

$disabled-border-color: $gray-5;

$input-max-width: rem(200px);

%defaultBorderStyle {
  border: $default-border;
  border-radius: 6px;
}

%focusedBorderStyle {
  outline: none;
  box-shadow: 0 0 4px 0 $blue-5;
  border: $focused-border;
}

.input {
  font-size: $font-size-medium;
  position: relative;
  padding: rem(13px) rem(15px);
  width: 100%;
  max-width: $input-max-width;
  @extend %defaultBorderStyle;

  &:focus {
    @extend %focusedBorderStyle;
  }

  @include placeHolderStyle {
    color: $gray-5;
  }
}

.taboola-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.hide-arrow-buttons {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  input:disabled {
    background-color: $white;
    border-color: $disabled-border-color;
  }

  .input-wrapper {
    position: relative;
    max-width: $input-max-width;
  }

  .label {
    font-weight: $regular-bolder;
    margin-bottom: rem(8px);
  }

  .description {
    font-size: rem(14px);
    color: $gray-7;
    margin-bottom: rem(10px);
  }

  &.error {
    .input {
      border: $error-border;
    }
  }

  &.warning {
    .input {
      border: $warning-border;
    }
  }

  &.full-width {
    .input {
      max-width: none;
    }
    .input-wrapper {
      max-width: none;
    }
  }

  &.with-icon {
    .input-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: rem(5px);
      display: flex;
      align-items: center;

      &:after {
        content: "";
        display: block;
        background-color: $gray-5;
        height: rem(20px);
        width: rem(1px);
        margin-left: rem(3px);
      }
    }
  }
}

.expandable-input-container {
  width: rem(30px);
  transition: width $animation-time ease-in-out;

  &:hover {
    cursor: pointer;
    .input {
      border: border($gray-6);
    }
  }

  .input {
    box-sizing: border-box;
    border: border($gray-4);
    padding: rem(6px) 0;
    color: transparent;
    transition: color 0s;
    transition-delay: 0s;

    &::placeholder {
      transition: opacity 0s;
      transition-delay: 0s;
      opacity: 0;
      color: $gray-5;
    }
  }

  &.open {
    width: rem(250px);
    cursor: text;

    .input {
      color: $text;
      transition: color $animation-time ease-in-out;
      transition-delay: $animation-time;
      padding: rem(6px) rem(20px) rem(6px) rem(30px);
      &::placeholder {
        transition: opacity $animation-time ease-in-out;
        transition-delay: $animation-time;
        opacity: 1;
      }
    }

    .input-icon {
      cursor: text;
    }
  }
}


.expandable-input {

  .input-icon {
    color: $gray-7;
    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(6px);
    display: flex;
    align-items: center;
    font-size: rem(20px);
    padding-bottom: rem(2px);
    outline: none;
    cursor: pointer;
  }

  .clear-icon {
    position: absolute;
    right: rem(4px);
    top: rem(1px);
    bottom: rem(1px);
    height: auto;
    padding: rem(1px);
  }
}

.expandable-input-clear-animation-enter {
  opacity: 0.01;
  transform: translateY(-10px);
}

.expandable-input-clear-animation-enter.expandable-input-clear-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 250ms ease-in;
}

.expandable-input-clear-animation-leave {
  opacity: 1;
  transform: translateY(0);
}

.expandable-input-clear-animation-leave.expandable-input-clear-animation-leave-active {
  opacity: 0.01;
  transform: translateY(-10px);
  transition: all 250ms ease-in;
}
