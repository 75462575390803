// Blue
$blue: #4D86EC;
$blue-1: #D3E1FB;
$blue-2: #84BBF9;
$blue-3: #71A9F1;
$blue-4: #5E99E9;
$blue-5: #4472C4;
$blue-6: #144C91;
$blue-7: #004B7A;
$blue-8: #054164;
$blue-9: #02324E;

// Green
$green-1: #F3FCF0;
$green-2: #88DD66;
$green-3: #00de8d;
$green-4: #01D98E;
$green-5: #00CB84;
$green-6: #00A86D;

// Gray
$gray-1: #F6F7F8;
$gray-2: #EEF0F2;
$gray-3: #EAECEF;
$gray-4: #D4D9DF;
$gray-5: #B8C1CA;
$gray-6: #9CA8B5;
$gray-7: #667686;
$gray-8: #4C5560;
$gray-9: #212832;

// Yellow
$yellow-1: #FFFAE7;
$yellow-2: #FFF6d4;
$yellow-3: #FFCB0A;
$yellow-4: #FFE278;

// Red
$red-1: #FDF0EF;
$red-2: #E86861;
$red-3: #E23E57;

//Teal
$teal-1: #F0FBFC;
$teal-2: #66D5DB;

// Others
$black: #000000;
$white: #FFFFFF;
$pink: #F1C4C2;
$text: $gray-9;


// All colors map
$allColorsMap: (
  // Blue
        blue: $blue,
        blue-1: $blue-1,
        blue-2: $blue-2,
        blue-3: $blue-3,
        blue-4: $blue-4,
        blue-5: $blue-5,
        blue-6: $blue-6,
        blue-7: $blue-7,
        blue-8: $blue-8,
        blue-9: $blue-9,
  // Green
        green-1: $green-1,
        green-2: $green-2,
        green-3: $green-3,
        green-4: $green-4,
        green-5: $green-5,
        green-6: $green-6,
  // gray
        gray-1: $gray-1,
        gray-2: $gray-2,
        gray-3: $gray-3,
        gray-4: $gray-4,
        gray-5: $gray-5,
        gray-6: $gray-6,
        gray-7: $gray-7,
        gray-8: $gray-8,
        gray-9: $gray-9,
  // Yellow
        yellow-1: $yellow-1,
        yellow-2: $yellow-2,
        yellow-3: $yellow-3,
        yellow-4: $yellow-4,
  // Red
        red-1: $red-1,
        red-2: $red-2,
        red-3: $red-3,
  // Teal
        teal-1: $teal-1,
        teal-2: $teal-2,
  // Others
        black: $black,
        white: $white,
        pink: $pink,
        text: $text
);
