$tbl-font-path: '../fonts' !default;

$tbl-css-prefix: tbl-icon !default;
$tbl-background-color: #eee !default;
$tbl-li-width: (30em / 14) !default;
$tbl-padding-width: (1em / 3) !default;

$tbl-week: '\ea2d';
$tbl-star-o: '\ea2e';
$tbl-sort: '\ea2f';
$tbl-target: '\ea30';
$tbl-status-rejected: '\ea31';
$tbl-show: '\ea32';
$tbl-settings: '\ea33';
$tbl-settings-sort: '\ea34';
$tbl-sort-down: '\ea35';
$tbl-save: '\ea36';
$tbl-right: '\ea37';
$tbl-publisher: '\ea38';
$tbl-plus: '\ea39';
$tbl-plus-button: '\ea3a';
$tbl-platform: '\ea3b';
$tbl-pixel: '\ea3c';
$tbl-sort-up: '\ea3d';
$tbl-ok: '\ea3e';
$tbl-ok-o: '\ea3f';
$tbl-notification: '\ea40';
$tbl-warning: '\ea41';
$tbl-menu: '\ea42';
$tbl-location: '\ea43';
$tbl-link: '\ea44';
$tbl-month: '\ea45';
$tbl-hide: '\ea46';
$tbl-help-o: '\ea47';
$tbl-overview: '\ea48';
$tbl-header-notifications: '\ea49';
$tbl-search: '\ea4a';
$tbl-export: '\ea4b';
$tbl-expend: '\ea4c';
$tbl-edit: '\ea4d';
$tbl-edit-o: '\ea4e';
$tbl-duplicate: '\ea4f';
$tbl-dropdown: '\ea50';
$tbl-delete: '\ea51';
$tbl-day: '\ea52';
$tbl-contract: '\ea53';
$tbl-close: '\ea54';
$tbl-left: '\ea55';
$tbl-campaigns: '\ea56';
$tbl-campaign: '\ea57';
$tbl-budget: '\ea58';
$tbl-help-export: '\ea59';
$tbl-archive: '\ea5a';
$tbl-apps: '\ea5b';
$tbl-alert: '\ea5c';
$tbl-block: '\ea5d';
$tbl-filter: '\ea5e';
$tbl-close-button: '\ea5f';
$tbl-taboola-small: '\ea60';
$tbl-mobile: '\ea61';
$tbl-cart: '\ea62';
$tbl-mail: '\ea63';
$tbl-speaker: '\ea64';
$tbl-eye: '\ea65';

$tbl-icons-font-stack: 'Taboola-Icons';
