@import './colors';
@import './variables';

$default-border-color: $gray-2;
$default-border-thickness: 1px;

:export {
  defaultBorderThickness: $default-border-thickness
}

@function border($color: $default-border-color, $thickness: $default-border-thickness) {
  @return $thickness solid $color;
}
