$root-font-size: 10px;

@function rem($pixels, $context: $root-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

$breakpoints: (
        'x-small': (min-width: rem(480px)),
        'small': (min-width: rem(768px)),
        'medium': (min-width: rem(992px)),
        'large': (min-width: rem(1200px)),
        'x-large': (min-width: rem(1400px)),
        'xx-large': (min-width: rem(1600px)),
) !default;

$font-size-x-small: rem(8px) !default;
$font-size-smaller: rem(11px) !default;
$font-size-small: rem(12px) !default;
$font-size-medium-small: rem(13px) !default;
$font-size-medium: rem(14px) !default;
$font-size-large: rem(16px) !default;
$font-size-larger: rem(18px) !default;
$font-size-x-large: rem(20px) !default;
$font-size-xx-large: rem(32px) !default;

//Typography
$regular-font-stack: 'Roboto', 'Open Sans', Arial, sans-serif;
$number-font-stack: $regular-font-stack;

//Font Weight
$light: 300;
$regular: 400;
$regular-bolder: 500;
$bold: 700;

//Z layers
$base-layer: 0;
$first-layer: 1;
$user-menu-layer: 2;
$date-range-picker-layer: 3;
$date-picker-layer: 10;
$side-drawer-overlay-layer: 998;
$side-drawer-layer: 999;

//scrollbar sizes
$custom-scrollbar-size: 11px;

$animation-time: 0.3s;

:export {
  rootFontSize: $root-font-size;
  fontSizeXSmall: $font-size-x-small;
  fontSizeSmaller: $font-size-smaller;
  fontSizeSmall: $font-size-small;
  fontSizeMediumSmall: $font-size-medium-small;
  fontSizeMedium: $font-size-medium;
  fontSizeLarge: $font-size-large;
  fontSizeLarger: $font-size-larger;
  fontSizeXLarge: $font-size-x-large;
  fontSizeXxLarge: $font-size-xx-large;
}