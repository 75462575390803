/*------------------------
	font icons
-------------------------*/
@import "variables";

.tbl-icon.tbl-week:before {
  content: $tbl-week;
}

.tbl-icon.tbl-warning:before {
  content: $tbl-warning;
}

.tbl-icon.tbl-target:before {
  content: $tbl-target;
}

.tbl-icon.tbl-status-rejected:before {
  content: $tbl-status-rejected;
}

.tbl-icon.tbl-star-o:before {
  content: $tbl-star-o;
}

.tbl-icon.tbl-sort:before {
  content: $tbl-sort;
}

.tbl-icon.tbl-sort-up:before {
  content: $tbl-sort-up;
}

.tbl-icon.tbl-sort-down:before {
  content: $tbl-sort-down;
}

.tbl-icon.tbl-show:before {
  content: $tbl-show;
}

.tbl-icon.tbl-settings:before {
  content: $tbl-settings;
}

.tbl-icon.tbl-settings-sort:before {
  content: $tbl-settings-sort;
}

.tbl-icon.tbl-search:before {
  content: $tbl-search;
}

.tbl-icon.tbl-save:before {
  content: $tbl-save;
}

.tbl-icon.tbl-right:before {
  content: $tbl-right;
}

.tbl-icon.tbl-publisher:before {
  content: $tbl-publisher;
}

.tbl-icon.tbl-plus:before {
  content: $tbl-plus;
}

.tbl-icon.tbl-plus-button:before {
  content: $tbl-plus-button;
}

.tbl-icon.tbl-platform:before {
  content: $tbl-platform;
}

.tbl-icon.tbl-pixel:before {
  content: $tbl-pixel;
}

.tbl-icon.tbl-overview:before {
  content: $tbl-overview;
}

.tbl-icon.tbl-ok:before {
  content: $tbl-ok;
}

.tbl-icon.tbl-ok-o:before {
  content: $tbl-ok-o;
}

.tbl-icon.tbl-notification:before {
  content: $tbl-notification;
}

.tbl-icon.tbl-month:before {
  content: $tbl-month;
}

.tbl-icon.tbl-menu:before {
  content: $tbl-menu;
}

.tbl-icon.tbl-location:before {
  content: $tbl-location;
}

.tbl-icon.tbl-link:before {
  content: $tbl-link;
}

.tbl-icon.tbl-left:before {
  content: $tbl-left;
}

.tbl-icon.tbl-hide:before {
  content: $tbl-hide;
}

.tbl-icon.tbl-help-o:before {
  content: $tbl-help-o;
}

.tbl-icon.tbl-help-export:before {
  content: $tbl-help-export;
}

.tbl-icon.tbl-header-notifications:before {
  content: $tbl-header-notifications;
}

.tbl-icon.tbl-filter:before {
  content: $tbl-filter;
}

.tbl-icon.tbl-export:before {
  content: $tbl-export;
}

.tbl-icon.tbl-expend:before {
  content: $tbl-expend;
}

.tbl-icon.tbl-edit:before {
  content: $tbl-edit;
}

.tbl-icon.tbl-edit-o:before {
  content: $tbl-edit-o;
}

.tbl-icon.tbl-duplicate:before {
  content: $tbl-duplicate;
}

.tbl-icon.tbl-dropdown:before {
  content: $tbl-dropdown;
}

.tbl-icon.tbl-delete:before {
  content: $tbl-delete;
}

.tbl-icon.tbl-day:before {
  content: $tbl-day;
}

.tbl-icon.tbl-contract:before {
  content: $tbl-contract;
}

.tbl-icon.tbl-close:before {
  content: $tbl-close;
}

.tbl-icon.tbl-close-button:before {
  content: $tbl-close-button;
}

.tbl-icon.tbl-campaigns:before {
  content: $tbl-campaigns;
}

.tbl-icon.tbl-campaign:before {
  content: $tbl-campaign;
}

.tbl-icon.tbl-budget:before {
  content: $tbl-budget;
}

.tbl-icon.tbl-block:before {
  content: $tbl-block;
}

.tbl-icon.tbl-archive:before {
  content: $tbl-archive;
}

.tbl-icon.tbl-apps:before {
  content: $tbl-apps;
}

.tbl-icon.tbl-alert:before {
  content: $tbl-alert;
}

.tbl-icon.tbl-taboola-small:before {
  content: $tbl-taboola-small;
}

 .tbl-icon.tbl-mobile:before {
  content: $tbl-mobile;
}

.tbl-icon.tbl-cart:before {
  content: $tbl-cart;
}

.tbl-icon.tbl-mail:before {
  content: $tbl-mail;
}

.tbl-icon.tbl-speaker:before {
  content: $tbl-speaker;
}

.tbl-icon.tbl-eye:before {
  content: $tbl-eye;
}