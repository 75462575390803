@import '../../styles/main';

:export {
  blue: $blue
}

.disabled {
  opacity: 0.4 !important;
}

.tbl-toggle-switch span {
  font-size: $font-size-medium;
  padding-left: 0.5rem;
}